<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <!-- end test -->
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Failed Third Party Orders</h4>
                  
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchFailedOrders()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1" @click="searchFailedOrders()">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-md-3 pad-r-0">
                          <select class="form-control" v-model="searchChannel" @change="searchFailedOrders()">
                            <option value="">Channel</option>
                            <option value="FOODIE">FOODIE</option>
                            <option v-for="channel in channels" :key="channel.id" :value="channel.channel_name">
                              {{channel.channel_name}}</option>
                          </select>
                        </div>
                        
                        
                      </div>
                    </div>
                  </div>

                  
                </div>
              </div>
              <hr>
            </div>
                
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Order Ref</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Channel</th>
                        <th scope="col">Failed Reason</th>
                        <th scope="col">Created At</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="order in failedOrders" :key="order.id" >
                        <td>{{ order.order_display_id }}</td>
                        <td>{{ order.order_id }}</td>
                        <td>{{ order.channel }}</td>
                        <td>{{ order.error_reason }}</td>
                        <td>{{ order.created_at !=undefined ? formatDate(order.created_at,1) : 'N/A' }}</td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="4">
                          <div id="pagination" v-if="totalPages > 1">
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
    
                            </ul>
                          </div>
    
                        </td>
                      </tr>
                    </tfoot>
                  </table>
    
                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
    
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
    import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
    import ReusableDataMixins from '@/mixins/ReusableDataMixins';
    import Pagination from '@/pages/pagination/pagination.vue';
    export default {
        components: {
            Pagination,
        },
        data() {
            return {
                failedOrders: [],
                loading: false,
                page: 1,
                totalPages: 0,
                recordsPerPage:10,
                search: '',
                status: '',
                channels:[],
                searchChannel:''
            }
        },
        mounted: async function () {
            this.getFailedOrders();
            this.getChannels();    
        },
        methods: {
            searchFailedOrders() {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.getFailedOrders();
                }, 1500)
            },
            
            async getFailedOrders(){
              this.loading = true;
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.baseUrl}/failed_orders`;
              url = new URL(url);
              url.searchParams.set('merchant_id', this.merchantID);
              url.searchParams.set('page', this.page);
              url.searchParams.set('size', this.recordsPerPage);
              if(this.search){
                  url.searchParams.set('order_id', this.search)
              }
              if(this.searchChannel){
                  url.searchParams.set('channel',this.searchChannel)
              }
  
              const response = await this.axios.get(url,config)
              if(response.status === 200){
                  this.loading = false;
                  this.failedOrders = response.data.data;
                  this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
                  this.totalRecords = response.data.total
              }
              
            },
            async getChannels() {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
                this.axios.get(url, config).then((response) => {
                    if (response.status === 200) {
                        this.channels = response.data.data
                    }
                });
            },
            onPageChange(page) {
                this.page = page
                this.getFailedOrders();
            },
            onChangeRecordsPerPage() {
                this.getFailedOrders();
            }
        },
        mixins: [ReusableFunctionMixins, ReusableDataMixins]
    }
    </script>
    